var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "goodsOnSale-list" },
    [
      _c("app-list", {
        ref: "appList",
        attrs: { opt: _vm.opt },
        on: { get: _vm.onGet }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.stockTitle,
            visible: _vm.dialogVisibleStock,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisibleStock = $event
            }
          }
        },
        [
          _c("el-form", { ref: "myForm", attrs: { model: _vm.myForm } }, [
            _c(
              "div",
              {
                staticClass: "formItem",
                staticStyle: { "font-weight": "bold" }
              },
              [
                _c("span", [_vm._v(_vm._s(_vm.myForm.groupName))]),
                _vm._v("  "),
                _c("span", [_vm._v(_vm._s(_vm.myForm.counterCode))]),
                _vm._v("  货柜\n            ")
              ]
            ),
            _c("div", { staticClass: "formItem" }, [
              _c("span", { staticStyle: { "margin-right": "10px" } }, [
                _vm._v(_vm._s(_vm.myForm.location))
              ]),
              _vm._v("缺货："),
              _c("span", { staticStyle: { color: "blue" } }, [
                _vm._v(_vm._s(_vm.myForm.lackDoorNum) + "个")
              ])
            ])
          ]),
          _c(
            "div",
            { staticClass: "deviceDetails-table" },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.tableDataLt, height: "400" }
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "code", align: "center", label: "柜门编号" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "type", align: "center", label: "货柜用途" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "itemStatus",
                      align: "center",
                      label: "货品状态"
                    }
                  })
                ],
                1
              ),
              _vm.tableDataRt.length > 0
                ? _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.tableDataRt, height: "400" }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "code",
                          align: "center",
                          label: "柜门编号"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "type",
                          align: "center",
                          label: "货柜用途"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "itemStatus",
                          align: "center",
                          label: "货品状态"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "dialog-footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.close()
                    }
                  }
                },
                [_vm._v("关闭")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }