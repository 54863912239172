var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "goodsOnSale" }, [_vm._v("实时售卖")]),
      _c("ul", { staticClass: "tab-ul" }, [
        _c(
          "li",
          {
            class: { ative: _vm.currentTab == "outStock" },
            on: {
              click: function($event) {
                _vm.toggleTab("outStock")
              }
            }
          },
          [_vm._v("缺货")]
        ),
        _c(
          "li",
          {
            class: { ative: _vm.currentTab == "onSale" },
            on: {
              click: function($event) {
                _vm.toggleTab("onSale")
              }
            }
          },
          [_vm._v("在售")]
        )
      ]),
      _c("keep-alive", [_c(_vm.currentTab, { tag: "outStock" })], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }