<template>
    <div class="goodsOnSale-list">
        <app-list :opt="opt" @get="onGet" ref="appList"></app-list>
        <el-dialog :title="stockTitle" :visible.sync="dialogVisibleStock" :close-on-click-modal="false">
            <el-form ref="myForm" :model="myForm">
                <div class="formItem" style="font-weight:bold">
                    <span>{{myForm.groupName}}</span>&nbsp;&nbsp;<span>{{myForm.counterCode}}</span>&nbsp;&nbsp;货柜
                </div>
                <div class="formItem">
                    <span style="margin-right:10px">{{myForm.location}}</span>缺货：<span
                        style="color:blue">{{myForm.lackDoorNum}}个</span>
                </div>
            </el-form>
            <div class="deviceDetails-table">
                <el-table :data="tableDataLt" height="400" style="width: 100%">
                    <el-table-column prop="code" align="center" label="柜门编号">
                    </el-table-column>
                    <el-table-column prop="type" align="center" label="货柜用途">
                    </el-table-column>
                    <el-table-column prop="itemStatus" align="center" label="货品状态">
                    </el-table-column>
                </el-table>
                <el-table :data="tableDataRt" height="400" style="width: 100%" v-if="tableDataRt.length>0">
                    <el-table-column prop="code" align="center" label="柜门编号">
                    </el-table-column>
                    <el-table-column prop="type" align="center" label="货柜用途">
                    </el-table-column>
                    <el-table-column prop="itemStatus" align="center" label="货品状态">
                    </el-table-column>
                </el-table>
            </div>
            <div class="dialog-footer">
                <el-button type="primary" @click="close()">关闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import cabinetUtil from "@utils/cabinetUtil.js"
    export default {
        data() {
            let _this = this;
            return {
                stockTitle: '缺货柜门信息',
                dialogVisibleStock: false,
                myForm: {
                    groupName: '',
                    counterCode: '',
                    lackDoorNum: '',
                    location: ''
                },
                tableDataLt: [], //左边表格数据
                tableDataRt: [], //右边表格数据,
                opt: {
                    search: [{
                        key: "merchantId",
                        label: "商家名称",
                        type: 'remoteSearchById',
                        opt: {
                            list: [],
                            change(data) {
                                _this.getStoreList(data)
                            }
                        }
                    }, {
                        key: "groupId",
                        label: "门店名称",
                        type: "remoteSearchById",
                        opt: {
                            list: []
                        }
                    }, {
                        key: "counterCode",
                        label: "货柜编号",
                    }],
                    columns: [{
                            label: "商家名称",
                            key: "merchantName"
                        },
                        {
                            label: "门店名称",
                            key: "groupName"
                        },
                        {
                            label: "货柜编号",
                            key: "counterCode"
                        },
                        {
                            label: "缺货柜门（个）",
                            key: "lackDoorNum",
                            on: row => {
                                _this.getDetail(row)
                            }
                        },
                        {
                            label: "满货柜门（个）",
                            key: "fullDoorNum",
                        },
                        {
                            label: "货柜（主）电量",
                            key: "batteryPower"
                        },
                        {
                            label: "货柜状态",
                            key: "stateStr"
                        },
                        {
                            label: "最近一次补货时间",
                            key: "recentTime"
                        },
                    ],
                    exportButtons: [{
                        type: "Export",
                        url: this.EXport + "/mall-service/v1/counter-info/export-lack-list",
                        listName: '缺货列表'
                    }],
                },
                merchantFlag: false,
            };
        },
        created() {
            console.log("goodsOnSale-list created!!");

        },
        activated() {
            console.log("goodsOnSale-list activated!!");
            this.merchantFlag = this.getLoginType('loginType');
            if (this.merchantFlag) {
                this.opt.search.forEach((item, index) => {
                    if (item.key == 'merchantId') {
                        this.opt.search.splice(index, 1);
                    }
                })
                this.opt.columns.forEach((item, index) => {
                    if (item.key == 'merchantName') {
                        this.opt.columns.splice(index, 1);
                    }
                })
            } else {
                this.opt.search.forEach((item, index) => {
                    if (item.key == 'merchantId') {
                        cabinetUtil.getMerchantList().then(res => {
                            item.opt.list = res;
                        })
                    }
                })
            }
        },
        deactivated() {
            this.dialogVisibleStock = false;
        },

        methods: {
            onGet(opt) {
                let dto = {
                    pageNum: opt.skip,
                    pageSize: opt.limit,
                    ...opt.searchForm
                };
                if (dto.date) {
                    delete dto.date
                }
                this.post("mall-service/v1/counter-info/get-lack-list", dto, {
                    isUseResponse: true
                }).then(res => {
                    let obj = {
                        data: []
                    };
                    obj.data = res.data.data.data;
                    obj.pages = res.data.data.pages;
                    obj.total = res.data.data.total;
                    res.data.data = res.data.data;
                    obj.data.forEach(item => {
                        item.merchantName = item.merchantName
                        item.stateStr = ['离线', '在线'][item.state];
                    })
                    opt.cb(obj);
                });
                if(opt.searchForm.merchantId) {
                    this.getStoreList(opt.searchForm.merchantId);
                }else{
                    this.getStoreList();
                }
            },
            //获取门店列表
            getStoreList(data) {
                let dto = {
                    currentPage: 1,
                    size: 99999,
                };
                if(data) {
                    dto.merchantId = data
                }
                this.post("/mall-service/v1/counterStores/getAllList", dto, {
                    isUseResponse: true,
                }).then(res => {
                    let storeList = res.data.data;
                    storeList = JSON.parse(JSON.stringify(storeList).replace(/groupName/g, "label"));
                    storeList = JSON.parse(JSON.stringify(storeList).replace(/groupId/g, "value"));
                    this.opt.search.forEach(item => {
                        if (item.key == 'groupId') {
                        item.opt.list = storeList;
                        }
                    })
                });
            },
            getDetail(row) {
                this.tableDataLt = [];
                this.tableDataRt = [];
                this.dialogVisibleStock = true;
                this.myForm.groupName = row.groupName;
                this.myForm.counterCode = row.counterCode;
                this.myForm.lackDoorNum = row.lackDoorNum;
                this.myForm.location = row.location;
                this.post("mall-service/v1/counter-info/get-lack-door-info?counterId=" + row.id, {
                    isUseResponse: true,
                }).then(res => {
                    res.forEach((item, index) => {
                        let purpose = ["存货柜", "购物柜", "电池柜"][item.type - 1];
                        let size = ["", "（大）", "（小）"][item.size]
                        item.type = purpose + size;
                        item.itemStatus = '缺货';
                        let midLength = Math.ceil(res.length / 2);
                        if (index < midLength) {
                            this.tableDataLt.push(item)
                        } else {
                            this.tableDataRt.push(item)
                        }
                    })

                })

            },
            close() {
                this.dialogVisibleStock = false;
            }
        }
    };
</script>
<style lang="scss" scope>
    .goodsOnSale-list {
        height: calc(100% - 130px);
    }

    .dialog-footer {
        text-align: center;
        margin-top: 20px;
    }

    .formItem {
        margin: 10px;
    }

    .el-dialog__header {
        text-align: center;
    }

    .el-dialog__body {
        padding-top: 0px
    }

    .el-dialog__title {
        text-align: center;
        font-weight: bold;
        font-size: 22px;
    }

    .app-list .row-nav-vice {
        cursor: pointer;
        color: #30BAC1;
        border-bottom: none !important
    }

    .stockTable {
        max-height: 500px;
        overflow: auto;
        margin-top: 0;
    }

    .el-dialog {
        margin-top: 15vh !important;
        width: 703px;
    }

    .el-table::before {
        z-index: 0;
    }
    .deviceDetails-table {
            display: flex;
        }
</style>