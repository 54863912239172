<template>
    <div>
        <div class="goodsOnSale">实时售卖</div>
        <ul class="tab-ul">
            <li :class="{'ative':currentTab=='outStock'}" @click="toggleTab('outStock')">缺货</li>
            <li :class="{'ative':currentTab=='onSale'}" @click="toggleTab('onSale')">在售</li>
        </ul>
        <keep-alive>
      <outStock :is="currentTab"></outStock>
    </keep-alive>
    </div>
</template>
<script>
    import outStock from './outStock/list.vue';
    import onSale from './onSale/list.vue';
    export default {
        components: {
            outStock,
            onSale,
        },
        data() {
            return {
                currentTab: 'outStock',
            }
        },
        activated() {
            let goodsType = this.cache.get('goodsType');
            if(goodsType) {
                this.currentTab = goodsType;
            }else {
                this.currentTab = 'outStock';
            }
        },
        deactivated() {
            let pathList = this.$route.path.split('/')
            if(pathList[2] != 'goodsOnSale') {
                this.cache.del('goodsType');
            }
        },
        methods: {
            toggleTab: function (tab) {
                this.currentTab = tab; // tab 为当前触发标签页的组件名
                this.cache.set('goodsType',tab);
            },
        },
    }
</script>
<style lang="scss" scoped>
    .goodsOnSale {
        font-size: 18px;
        color: #303133;
        margin-bottom: 16px;
        padding-top: 8px;
    }
    // tab样式
    .tab-ul {
        list-style: none;
        padding-left: 0;
        margin-top: 0;
        height: 42px;
        box-sizing: border-box;
        margin-bottom: 40px;
        width: 100%;
        border-bottom: 2px solid #e4e7ed;

        li {
            cursor: pointer;
            padding-left: 18px;
            padding-right: 18px;
            height: 42px;
            line-height: 42px;
            float: left;
            text-align: center;
            margin-right: 13px;
            font-size: 15px;
            background-color: #ffffff;
            color: #303133;
            box-sizing: border-box;
            border-bottom: 2px solid #e4e7ed;

            &.ative {
                // color: #ffffff;
                // background: #5e74f1;
                color: #30BAC1;
                border-bottom: 2px solid #30BAC1;
            }
        }

        &::after {
            content: "";
            display: block;
            clear: both;
        }
    }
</style>